<template>
    <el-container>
        <el-aside width="613px">
            <el-container>
                <el-header height="80px">
                    <el-form :inline="true">
                        <el-form-item label="查时间：">
                            <el-date-picker v-model="dateRange" type="daterange" start-placeholder="开始日期"
                                end-placeholder="结束日期" value-format="yyyy-MM-dd" class="date-range" range-separator="至"
                                :editable="false" :clearable="true" @change="handleQuery()"></el-date-picker>
                        </el-form-item>
                        <el-form-item label="查货号：">
                            <el-input v-model="queryParam.ItemNo" style="width: 100px" placeholder="请输入货号"></el-input>
                        </el-form-item>
                        <el-form-item label="仓库：" v-show="$store.state.routes.systemSetting.MultiWarehouse">
                            <warehouse-select :value.sync="queryParam.WID" select_style="width:117px;"></warehouse-select>
                        </el-form-item>
                        <el-form-item style="float:right;">
                            <el-button-group>
                                <el-button type="primary" @click="handleQuery()">查询</el-button>
                                <el-button type="default" @click="reset()">重置</el-button>
                            </el-button-group>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary" @click="handleExport(0)" :loading="exportLoading">导出Excel</el-button>
                        </el-form-item>
                    </el-form>
                </el-header>
                <el-main>
                    <grid-table ref="gridTable" :showIndexColumn="true" :showLastEmptyColumn="false" :showPagination="true"
                        :tableConfig="tableConfig" @selectionChange="handleSelectionChange" @handleRowClick="handleRowClick"
                        :showCheckboxColumn="false" :resizable="false">
                        <template slot="StorageCode" slot-scope="{ params }">
                            {{ params.row['StorageCode'] }}
                            <template v-if="$store.state.routes.systemSetting.MultiWarehouse">
                                <br />
                                <span class="span_wname">[{{ params.row['WName'] ? params.row['WName'] : '默认仓库'
                                    }}]</span>
                            </template>
                        </template>
                    </grid-table>
                </el-main>
            </el-container>
        </el-aside>
        <el-main>
            <el-container>
                <el-header height="40px">
                    <span class="span-spec-name" v-if="detail.stock != null">【{{ detail.stock.AddTime
                        }}】{{ detail.stock.DetailTypeStr }}</span>
                    <span class="span-spec-count">仓库：{{ detail.stock != null ? detail.stock.WName : '' }}</span>
                    <span class="span-spec-count">货号合计：{{ calcDetailCount('itemNo') }}</span>
                    <span class="span-spec-count">sku合计：{{ calcDetailCount('sku') }}</span>
                    <span class="span-spec-count">数量总计：{{ calcDetailCount('Amount') }}</span>
                    <span style="float:right;"><el-button type="primary" @click="handleExport(1)"
                            :loading="exportOneLoading" v-if="detail.stock != null">导出Excel</el-button></span>
                </el-header>
                <el-main>
                    <el-table ref="detailTable" :span-method="objectSpanMethod" :data="detail.stockDetails"
                        v-loading="detail.loading" border :header-cell-style="{
                            'background-color': '#E5EEF6',
                            'border-color': 'rgba(218, 223, 228, 1)',
                            height: '48px',
                        }" height="100%">
                        <el-table-column label="序号" width="80px" align="center">
                            <template slot-scope="scope">
                                {{ scope.$index + 1 }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="ItemNo" label="货号" header-align="center" align="center" width="180"
                            :resizable="false">
                            <template slot-scope="{ row }">
                                {{ row.ItemNo }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="Color" label="颜色" header-align="center" align="center" :resizable="false">
                            <template slot-scope="{ row }">
                                {{ row.Color }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="Size" label="尺码" header-align="center" align="center" :resizable="false">
                            <template slot-scope="{ row }">
                                {{ row.Size }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="Amount" label="变动数量" header-align="center" align="center" :resizable="false">
                            <template slot-scope="{ row }">
                                {{ row.Amount }}
                            </template>
                        </el-table-column>
                    </el-table>
                </el-main>
            </el-container>
        </el-main>
    </el-container>
</template>
  
<script>
import { submit, exportFile } from "@/api/base";
import { getDate } from '@/utils/tool';
import dayjs from "dayjs";
import WarehouseSelect from "@/views/components/common/WarehouseSelect.vue";
export default {
    name: "storageIndex",
    components: {
        WarehouseSelect
    },
    data() {
        return {
            routeParam: {},
            tableConfig: {
                url: "/api/wms/storageQuery/getStorageList",
                rowKey: "StorageCode",
                columns: [
                    {
                        label: "出入库单号",
                        prop: "StorageCode",
                        type: "custom-operation",
                        width: "190px",
                    },
                    {
                        label: "时间",
                        prop: "AddTime",
                        type: "text-datetime",
                        dateFormat: "yyyy-MM-dd",
                        width: "105px",
                    },
                    {
                        label: "出入库类型",
                        prop: "DetailTypeStr",
                        type: "text",
                        width: "110px",
                    },
                    {
                        label: "备注",
                        prop: "Note",
                        type: "text"
                    }
                ],
            },
            dateRange: [],
            queryParam: {
                ItemNo: "",
                StartTime: '',
                EndTime: '',
                WID: undefined,
            },
            detail: {
                loading: false,
                stock: null,
                stockDetails: [],
            },
            exportLoading: false,
            exportOneLoading: false,
            selectRows: [],
            mergeObj: {}, // 用来记录需要合并行的下标
            mergeArr: ["ItemNo"], // 表格中的列名
        };
    },
    mounted() {
        this.$nextTick(async () => {
            const today = getDate();
            //this.dateRange = [today, today];
            this.handleQuery();
        });
    },
    methods: {
        reset() {
            this.queryParam.SupplierName = "";
            this.queryParam.ItemNo = "";
            this.queryParam.WID = "";
            this.handleQuery();
        },
        objectSpanMethod({ row, column, rowIndex, columnIndex }) {
            if (this.mergeArr.indexOf(column.property) !== -1) {
                if (this.mergeObj[column.property][rowIndex]) {
                    return [this.mergeObj[column.property][rowIndex], 1];
                } else {
                    return [0, 0];
                }
            }
        },
        getSpanArr() {
            this.detail.stockDetails = this.detail.stockDetails.sort(function (a, b) {
                if (a.ProductID !== b.ProductID) return a.ProductID < b.ProductID ? -1 : 1
                else if (a.Color !== b.Color) return a.Color < b.Color ? -1 : 1
                else if (a.Size !== b.Size) return a.Size < b.Size ? -1 : 1
            });
            let that = this;
            this.mergeArr.forEach(key => {
                let itemIndex = 0;
                that.mergeObj[key] = [];
                that.detail.stockDetails.forEach((item, index) => {
                    if (index === 0 || (index > 0 && item[key] != that.detail.stockDetails[index - 1][key])) {
                        itemIndex = index;
                        that.mergeObj[key].push(1);
                    } else {
                        that.mergeObj[key][itemIndex] += 1;
                        that.mergeObj[key].push(0);
                    }
                });
            });
        },
        calcDetailCount(type) {
            var that = this;
            var count = 0;
            if (type == 'itemNo') {
                let temp = {};
                for (let x = 0; x < that.detail.stockDetails.length; x++) {
                    temp[that.detail.stockDetails[x]['ItemNo']] = 1;
                }
                count = Object.keys(temp).length;
            }
            else if (type == 'sku') {
                let temp = {};
                for (let x = 0; x < that.detail.stockDetails.length; x++) {
                    temp[that.detail.stockDetails[x]['SpecID']] = 1;
                }
                count = Object.keys(temp).length;
            }
            else {
                for (let x = 0; x < that.detail.stockDetails.length; x++) {
                    let typeValue = that.detail.stockDetails[x][type];
                    count += typeValue;
                }
            }
            return count;
        },
        removeEditData() {
            this.detail.stock = null;
            this.detail.stockDetails = [];
        },
        handleQuery() {
            var that = this;
            that.queryParam.StartTime = '';
            that.queryParam.EndTime = '';
            if (that.dateRange && that.dateRange.length > 0) {
                that.queryParam.StartTime = that.dateRange[0];
                that.queryParam.EndTime = that.dateRange[1];
            }
            that.$refs.gridTable.searchParam = { ...that.queryParam };
            delete that.$refs.gridTable.searchParam.dateRange;
            that.$refs.gridTable.fetchData();
            that.removeEditData();
        },
        handleSelectionChange(selection) {
            this.selectRows = selection;
            if (selection.length <= 0) {
                this.removeEditData();
            }
        },
        handleRowClick(row) {
            var that = this;
            that.rowClick(row);
        },
        async rowClick(row) {
            var that = this;
            that.$refs.gridTable.$refs.gridTable.clearSelection();
            that.$refs.gridTable.$refs.gridTable.toggleRowSelection(row);
            that.detail.loading = true;//防止单个采购单太大或电脑性能问题导致太久没反应

            that.detail.stock = null;
            that.detail.stockDetails = [];
            const result = await submit("/api/wms/storageQuery/getStorageDetails", { StorageID: row.StorageID });
            if (result.success) {
                that.detail.stock = row;
                that.detail.stockDetails = result.data;
                that.getSpanArr();
            }
            this.detail.loading = false;
        },
        async handleExport(type) {
            var that = this;
            const today = dayjs().format('YYYYMMDD');
            var param = {};
            if (type == 1) {
                that.exportOneLoading = true;
                param = { StorageID: that.detail.stock.StorageID };
            } else {
                that.exportLoading = true;
                param = that.queryParam;
            }
            const { data } = await exportFile('/api/wms/storageQuery/exportDetails', param);
            const blob = new Blob([data.Blob], { type: "application/vnd.ms-excel" });
            var a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = '出入库明细_' + today + '.xls';
            a.style.display = "none";
            document.body.appendChild(a);
            a.click();
            a.remove();
            if (type == 1) {
                that.exportOneLoading = false;
            } else {
                that.exportLoading = false;
            }
        },
    },
};
</script>
  
<style lang="scss" scoped>
::v-deep {
    .span_wname {
        color: #9e9e9e;
        font-size: 12px;
    }

    .el-aside {
        .el-header {
            .date-range {
                width: 200px;

                .el-range-input {
                    width: 70px;
                }
            }

            .el-form-item__label {
                padding-right: 0;
            }
        }
    }

    .el-main {
        .el-header {
            .span-spec-name {
                font-weight: bold;
                font-size: 20px;
            }

            .span-spec-count {
                margin-left: 20px;
                font-size: 16px;
            }
        }

        .el-table {
            .size-tag {
                height: 36px;
                line-height: 33px;
                margin: 6px 5px 3px 0px;
                float: left;

                .el-input--small .el-input__inner {
                    height: 22px;
                    line-height: 22px;
                    padding: 0px;
                    text-align: center;
                }
            }

            .tag-item:hover .icon-delete {
                display: block;
            }

            .span-delete {
                color: red;
                font-size: 12px;
                cursor: pointer;
                position: absolute;
                margin-top: 15px;
            }

            .span-delete:hover {
                text-decoration: underline;
            }

            .icon-delete {
                background-color: red;
                border-radius: 10px;
                color: #fff;
                opacity: 0.8;
                float: left;
                cursor: pointer;
                display: none;
                margin-left: -15px;
            }
        }
    }
}
</style>
  